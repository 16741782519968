import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { PayPalButton } from 'react-paypal-button-v2'
import styled, { css, keyframes } from 'styled-components'
import { rgba } from 'polished'
import { Box } from 'rebass/styled-components'
import AnimateHeight from 'react-animate-height'

import Total from './total'
import PaymentStatus from './payment-status'
import { BuyButton } from './button'
import { theme } from '../styles/theme'
import { isBrowser } from '../utils/isBrowser'

const client = {
  sandbox:
    'AQzonyguKwNsNVRUu4mWG3rTKEd78XvykFSZLDS8MvV6f6saEyrJ-ktP2DQlqSYmVO4X-7pQprDWRMCo',
  production: 'YOUR-PRODUCTION-APP-ID',
}

const fadeBackground = keyframes`
  0% {
    background-color: ${rgba('#000', 0.05)};
  }
  100% {
    background-color: ${rgba('#000', 0.1)};
  }
`

const wrapperAnimation = css`
  animation: 1s ${(props) => props.theme.easing} infinite alternate
    ${fadeBackground};
  will-change: background-color;
`

export const PaymentButtonWrapper = styled(Box)`
  position: relative;
  background-color: #2c2e2f;
  /* This prevents additional few pixels below loaded PayPal button */
  line-height: 0;
  /* This will animate PayPal button wrapper's background until external button is loaded */
  ${(props) => (props.animateBackground ? wrapperAnimation : null)}
`

export const PayPalButtonLoading = styled.p`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`

const PaymentButton = ({
  currency,
  title,
  total,
  quantity,
  price,
  shippingPrice,
  env,
  intl,
  tax,
  taxRate,
}) => {
  // This shows table with price breakdown and PayPal button
  const [buy, setBuy] = useState(false)
  // Shows loading when PayPal button script is not yet loaded
  const [paypalReady, setPaypalReady] = useState(false)
  // Status of payment used to show message to customer
  const [paymentStatus, setPaymentStatus] = useState({
    status: false,
    message: '',
  })

  const onPaymentSuccess = (details, data) => {
    console.log('Payment successful!', details, data)
    setBuy(false)
    setPaymentStatus({
      status: 'success',
      message: intl.formatMessage({
        id: 'payment.successful',
        defaultMessage:
          'Payment successful. E-mail with confirmation will arrive soon!',
      }),
    })
  }

  const onPaymentCancel = (data) => {
    console.log('Payment cancelled!', data)
    setPaymentStatus({
      status: 'cancelled',
      message: intl.formatMessage({
        id: 'payment.cancelled',
        defaultMessage: 'Payment cancelled. Please try again.',
      }),
    })
  }

  const onPaymentError = (err) => {
    console.log('Error!', err)
    setPaymentStatus({
      status: 'error',
      message: intl.formatMessage({
        id: 'payment.failed',
        defaultMessage: 'Payment failed. Please try again.',
      }),
    })
  }

  return (
    <Box>
      {paymentStatus.status && (
        <PaymentStatus status={paymentStatus.status}>
          {paymentStatus.message}
        </PaymentStatus>
      )}

      <AnimateHeight
        duration={800}
        easing={theme.easing}
        height={buy ? 'auto' : 0}
      >
        {quantity > 0 && (
          <Box mb={3}>
            <Total
              quantity={Number(quantity)}
              total={Number(total)}
              currency={currency}
              unitPrice={Number(price)}
              productName={title}
              shippingPrice={Number(shippingPrice)}
              tax={Number(tax)}
              taxRate={Number(taxRate)}
            />
          </Box>
        )}
      </AnimateHeight>

      {!buy && (
        <BuyButton
          type="button"
          onClick={() => {
            setBuy(true)
            setPaymentStatus({
              status: false,
              message: '',
            })
          }}
        >
          <FormattedMessage id="product.buy" defaultMessage="Buy" />
        </BuyButton>
      )}

      {buy && (
        <Box>
          {isBrowser && currency && total && (
            <PaymentButtonWrapper
              minHeight={'45px'}
              animateBackground={!paypalReady}
            >
              {
                <PayPalButtonLoading>
                  <FormattedMessage
                    id="product.paypal.loading"
                    defaultMessage="Loading payment gateway..."
                  />
                </PayPalButtonLoading>
              }

              <PayPalButton
                // precision is 2 decimal places
                amount={total.toFixed(2)}
                currency={currency.toUpperCase()}
                options={{
                  locale: intl.locale === 'en' ? 'en_US' : 'cs_CZ',
                  currency: currency.toUpperCase(),
                  clientId: client.sandbox,
                  disableFunding: 'credit,card',
                  debug: env === 'dev',
                  integrationDate: '2019-12-04',
                }}
                style={{
                  color: 'black',
                  label: 'buynow',
                  branding: true,
                  shape: 'rect',
                  size: 'responsive',
                  tagline: false,
                }}
                onButtonReady={() => setPaypalReady(true)}
                onSuccess={(payment) => onPaymentSuccess(payment)}
                onCancel={(data) => onPaymentCancel(data)}
                onError={(err) => onPaymentError(err)}
                onClick={() => {
                  setPaymentStatus({
                    status: 'progress',
                    message: intl.formatMessage({
                      id: 'payment.progress',
                      defaultMessage: 'Payment in progress...',
                    }),
                  })
                }}
              />
            </PaymentButtonWrapper>
          )}
        </Box>
      )}
    </Box>
  )
}

PaymentButton.propTypes = {
  currency: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  taxRate: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  shippingPrice: PropTypes.number.isRequired,
  env: PropTypes.string.isRequired,
  intl: PropTypes.object,
}

export default injectIntl(PaymentButton)
